/*
 * @Autor: ROT
 * @LastEditors: ROT
 * @Description: 
 * @Date: 2023-05-23 09:17:46
 * @LastEditTime: 2023-07-11 15:35:55
 * @FilePath: \vue-fall-emergency\src\store\index.js
 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mainRouteName: "login",
    title: "意外跌倒时的实时安抚",
    student: {
      name: "李瑶"
    }
  },
  getters: {},
  mutations: {
    SET_ROUTE_NAME(state, data) {
      state.mainRouteName = data
    },
    SET_TITLE(state, data) {
      state.title = data

      if (data == '模块三——升星考核评价表') {
        state.student.name = '阳嘉红'
      }
    }
  },
  actions: {},
  modules: {}
})