<!--
 * @Autor: ROT
 * @LastEditors: ROT
 * @Description: 
 * @Date: 2023-05-25 15:52:29
 * @LastEditTime: 2023-06-15 13:50:04
 * @FilePath: \vue-fall-emergency\src\pages\index.vue
-->

<template>
  <div class="wrap">
    <div class="top-bg">
      <div class="title1">
        <el-select
          v-model="project_name"
          filterable
          allow-create
          style="width:7rem"
          default-first-option
          placeholder="请选择/输入项目名称"
          @change="changeProjectName"
        >
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </div>

    <div class="title">欢迎使用</div>

    <div class="content">
      <p v-if="project_name !='临终关怀时的VR慰藉'">（胡/张老师）</p>
      <p v-else>（谢老师）</p>
      <div class="buttons">
        <template v-if="project_name !='临终关怀时的VR慰藉'">
          <div class="button" @click="nav('introduce')">希沃触控大屏端</div>
          <div class="button" @click="nav('grade')">教师评委平板端</div>
          <!-- <div class="button" v-show="" @click="nav('mobilePhone')">学生评委手机端</div> -->
          <div class="button" @click="nav('elderlyList')">护理员(考)平板端</div>
          <div class="button" @click="nav('studentList')">学生后台管理端</div>
        </template>

        <template v-if="project_name =='临终关怀时的VR慰藉'">
          <div class="button" @click="nav('dash')">能力分析库</div>
          <div class="button" @click="nav('audioSelect')">老年沟通资源库</div>
          <div class="button" @click="nav('xieStudent')">学生操作端</div>
        </template>

        <!-- <div class="button">老年沟通资源库</div> -->
        <div class="button">照护沟通技能库</div>
        <div class="button">岗位案例库</div>
        <div class="button">虚拟老人照护训练库</div>
        <div class="button">赛项真题库</div>
        <div class="button">模拟赛事库</div>
        <div class="button">测验库</div>
        <!-- <div class="button">能力分析库</div> -->
        <div class="button">泰养云</div>
      </div>
    </div>

    <div class="bottom-bg"></div>
    <div class="copyright"></div>
  </div>
</template>

<script>
import "@/util/flexiable"
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 20,
      totalCount: 100,
      options: [
        {
          label: '意外跌倒时的实时安抚',
          value: '意外跌倒时的实时安抚'
        },
        {
          label: '模块三——升星考核评价表',
          value: '模块三——升星考核评价表'
        },
        {
          label: '临终关怀时的VR慰藉',
          value: '临终关怀时的VR慰藉'
        },
      ],
      project_name: "意外跌倒时的实时安抚"
    }
  },
  computed: {
    screenWidth() {
      return document.body.clientWidth
    }
  },
  mounted() {
    this.project_name = this.$store.state.title

    // if (this.screenWidth < 768) {
    //   this.$router.push({
    //     name: 'mobilePhone'
    //   })
    // }
  },
  methods: {
    changeProjectName() {
      this.$store.commit("SET_TITLE", this.project_name)
    },
    nav(name) {
      this.$router.push({ name })
      if (!this.fullscreen)
        this.screen()
    },
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
  }
}
</script>

<style scoped lang="scss">
p {
  font-size: 0.625rem;
}

.wrap {
  width: 100vw;
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(17, 30, 45, 1),
    rgba(14, 21, 30, 1) 100%
  );
}

.top-bg {
  position: absolute;
  width: 23.0375rem;
  height: 2.4625rem;
  top: 0.3rem;
  left: 0.5375rem;
  background-image: url("/public/img/bg_img_home_top.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.title1 {
  color: rgb(255, 255, 255);
  font-family: PingFang SC;
  font-size: 0.6rem;
  font-weight: 400;
  letter-spacing: 0.125rem;
  margin-top: 0.6rem;
}

.bottom-bg {
  position: absolute;
  width: 23.0375rem;
  height: 0.7375rem;
  bottom: 0.1375rem;
  left: 0.5375rem;
  background-image: url("/public/img/bg_img_home_bottom.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.title {
  width: 4.6rem;
  height: 0.9125rem;
  line-height: 0.9125rem;
  position: absolute;
  top: 1.95rem;
  left: 0.875rem;
  background-image: url("/public/img/img_theme.png");
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 0.325rem;
  color: #fff;
}

.content {
  width: 22.5rem;
  height: 8.5rem;
  position: absolute;
  top: 3.2125rem;
  left: 0.875rem;
  background-image: url("/public/img/bg_manage.png");
  background-size: contain;
  background-repeat: no-repeat;
  p {
    font-size: 0.25rem !important;
    color: #fff;
    padding-top: 0.25rem;
  }

  .buttons {
    padding: 0.6rem 1.2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 0.3rem;
    color: #fff !important;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
    gap: 1rem;
    .item {
      display: flex;
      width: 5.2125rem;
      height: 2.5rem;
      background-image: url("/public/img/bg_nurse_information_default.png");
      background-size: contain;
      background-repeat: no-repeat;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;

      .avatar-box {
        width: 1.3875rem;
        height: 1.3875rem;
        background-image: url("/public/img/img_user_voice.png");
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 0.35rem;
        img {
          width: 1rem;
          height: 1rem;
          margin-top: 0.2rem;
        }
        div {
          margin-top: 0.225rem;
          font-size: 0.275rem;
          color: #fff;
        }
      }

      .info-box {
        width: 3.25rem;
        font-size: 0.25rem;
        color: #fff;
        .text {
          display: flex;
          gap: 0.25rem;
          flex-wrap: wrap;
        }

        .button {
          width: 1.475rem;
          height: 0.5rem;
          line-height: 0.5rem;
          font-size: 0.275rem;
          color: #fff;
          background-image: url("/public/img/btn_select.png");
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: 1.6rem;
          cursor: pointer;
        }

        .button:hover {
          background-image: url("/public/img/btn_select_press.png");
        }
      }
    }
  }
}

.copyright {
  width: 5.4375rem;
  height: 0.3125rem;
  position: absolute;
  top: 0.3rem;
  left: 0.575rem;
  background-image: url("/public/img/img_pxwd.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
}
</style>

<style scoped>
/deep/.el-pagination__total,
/deep/.el-pagination__jump {
  color: #fff;
  font-size: 0.25rem !important;
}

/deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #999;
  font-size: 0.25rem;
  width: 0.45rem;
  height: 0.45rem;
  line-height: 0.45rem;
}

/deep/.el-pagination.is-background .el-pager li {
  font-size: 0.25rem;
  width: 0.45rem;
  height: 0.45rem;
  line-height: 0.45rem;
  background-color: rgba(40, 166, 255, 0.33);
  color: #fff;
}

/deep/.btn-prev,
/deep/.btn-next {
  font-size: 0.25rem;
  width: 0.45rem;
  height: 0.45rem;
  line-height: 0.45rem;
  background-color: rgba(40, 166, 255, 0.33);
  color: #fff;
}

.red-active {
  border: 2px solid red;
  border-radius: 0.3rem;
}

.button {
  width: 4.4125rem;
  height: 1rem;
  line-height: 1rem;
  border-radius: 0.075rem;
  color: #fff;
  font-size: 0.3rem;
  background-color: rgba(40, 166, 255, 0.2);
  margin-bottom: 0.375rem;
}
.button:hover {
  cursor: pointer;
  background-color: rgba(40, 166, 255, 1);
}
/deep/.el-input__inner {
  padding: 40px;
  font-size: 0.5rem;
}

/deep/.el-select-dropdown{
  background-color: rgba(40, 166, 255, 0.2) !important;
}

/deep/.el-input__inner{
  color: #fff;
  background-color:transparent !important;
}
</style>