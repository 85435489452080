/*
 * @Autor: ROT
 * @LastEditors: 羊驼
 * @Description: 
 * @Date: 2023-05-23 09:17:46
 * @LastEditTime: 2023-07-12 15:48:41
 * @FilePath: \vue-fall-emergency\src\main.js
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import '../theme/index.css'
import 'element-ui/lib/theme-chalk/index.css';
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import 'viewerjs/dist/viewer.css'
import { BootstrapVue } from 'bootstrap-vue'
import AudioVisual from 'vue-audio-visual'
import * as echarts from 'echarts'
import VueViewer from 'v-viewer'
import 'animate.css'
Vue.prototype.$echarts = echarts
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(AudioVisual)
Vue.use(VXETable)
Vue.use(BootstrapVue)
Vue.use(VueViewer)
/**
 this.$viewerApi({
          images: data.urls,
        })
 */
Vue.config.errorHandler = function (err, vm, info) {
  console.error(err);
  info.preventDefault(); // 阻止错误信息在页面中显示
};


Vue.prototype.$enterFullScreen = () => {
  let el = document.documentElement;
  var isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
  if (!isFullscreen) { //进入全屏,多重短路表达式
    (el.requestFullscreen && el.requestFullscreen()) ||
      (el.mozRequestFullScreen && el.mozRequestFullScreen()) ||
      (el.webkitRequestFullscreen && el.webkitRequestFullscreen()) || (el.msRequestFullscreen && el.msRequestFullscreen());
  } else { //退出全屏,三目运算符
    document.exitFullscreen ? document.exitFullscreen() :
      document.mozCancelFullScreen ? document.mozCancelFullScreen() :
        document.webkitExitFullscreen ? document.webkitExitFullscreen() : '';
  }


}


Vue.prototype.$backIndex = () => {
  console.log(store.state.mainRouteName);
  router.push({ name: store.state.mainRouteName })
}

Vue.prototype.$random = (min, max) => {
  return Number((Math.random() * (max - min) + min).toFixed(0))
}



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
