/*
 * @Autor: ROT
 * @LastEditors: ROT
 * @Description: 
 * @Date: 2023-05-23 09:17:46
 * @LastEditTime: 2023-07-17 17:47:48
 * @FilePath: \vue-fall-emergency\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../pages/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/home',
    name: 'home',
    component: HomeView
  },
  {
    path: '/',
    name: 'login2',
    component: () => import('../pages/login/index.vue')
  },
  // {
  //   path: '/',
  //   name: 'cover',
  //   component: () => import('../pages/cover.vue')
  // },
  //胡老师的页面路由-开始
  {
    path: '/hu01',
    name: 'hu01',
    component: () => import('../pages/hu01/index2.vue')
  },
  {
    path: '/hu011',
    name: 'hu01',
    component: () => import('../pages/hu01/index.vue')
  },
  {
    path: '/hu01/introduce',
    name: 'hu01-introduce',
    component: () => import('../pages/hu01/Introduce.vue')
  },
  {
    path: '/hu01/voice',
    name: 'hu01-voice',
    component: () => import('../pages/hu01/voice.vue')
  },
  {
    path: '/hu01/waiting',
    name: 'hu01-waiting',
    component: () => import('../pages/hu01/waiting.vue')
  },
  {
    path: '/hu01/grade',
    name: 'hu01-grade',
    component: () => import('../pages/hu01/grade.vue')
  },
  {
    path: '/hu01/mobilePhone',
    name: 'hu01-mobilePhone',
    component: () => import('../pages/hu01/mobilePhone.vue')
  },
  {
    path: '/hu01/mobilePhone1',
    name: 'hu01-mobilePhone1',
    component: () => import('../pages/hu01/mobilePhone1.vue')
  },
  {
    path: '/hu01/mobilePhone2',
    name: 'hu01-mobilePhone2',
    component: () => import('../pages/hu01/mobilePhone2.vue')
  },
  {
    path: '/hu01/mobilePhone3',
    name: 'hu01-mobilePhone3',
    component: () => import('../pages/hu01/mobilePhone3.vue')
  },
  {
    path: '/hu01/elderlyList',
    name: 'hu01-elderlyList',
    component: () => import('../pages/hu01/elderlyList.vue')
  },
  {
    path: '/hu01/elderlyList2',
    name: 'hu01-elderlyList2',
    component: () => import('../pages/hu01/elderlyList2.vue')
  },
  {
    path: '/hu01/studentList',
    name: 'hu01-studentList',
    component: () => import('../pages/hu01/studentList.vue')
  },
  {
    path: '/hu01/result',
    name: 'hu01-result',
    component: () => import('../pages/hu01/result.vue')
  },
  {
    path: '/hu01/detail',
    name: 'hu01-detail',
    component: () => import('../pages/hu01/detail.vue')
  },
  {
    path: '/hu01/studentList',
    name: 'hu01-studentList',
    component: () => import('../pages/hu01/studentList.vue')
  },
  {
    path: '/hu01/detail3',
    name: 'hu01-detail3',
    component: () => import('../pages/hu01/studentDetail.vue')
  },
  {
    path: '/end7/result/:id',
    name: 'end7-result',
    component: () => import('../pages/end7/result.vue')
  },
  {
    path: '/hu01/result5',
    name: 'hu01-result5',
    component: () => import('../pages/hu01/result5.vue')
  },
  //胡老师的页面路由-结束



  //张老师-开始
  {
    path: '/zhang02',
    name: 'zhang02',
    component: () => import('../pages/zhang02/index.vue')
  },
  {
    path: '/zhang02/voice',
    name: 'zhang02-waitgrade',
    component: () => import('../pages/zhang02/waitgrade.vue')
  },
  {
    path: '/zhang02/grade',
    name: 'zhang02-grade',
    component: () => import('../pages/zhang02/grade.vue')
  },
  {
    path: '/zhang02/elderlyList',
    name: 'zhang02-elderlyList',
    component: () => import('../pages/zhang02/elderlyList.vue')
  },
  {
    path: '/zhang02/studentList',
    name: 'zhang02-studentList',
    component: () => import('../pages/zhang02/studentList.vue')
  },
  {
    path: '/zhang02/detail',
    name: 'zhang02-detail3',
    component: () => import('../pages/zhang02/studentDetail.vue')
  },
  {
    path: '/zhang02/jiexiaoPC',
    name: 'zhang02-jiexiaoPC',
    component: () => import('../pages/zhang02/jiexiaoPC.vue')
  },
  {
    path: '/zhang02/jiexiaoMB',
    name: 'zhang02-jiexiaoMB',
    component: () => import('../pages/zhang02/jiexiaoMB.vue')
  },
  {
    path: '/zhang02/huodong',
    name: 'zhang02-huodong',
    component: () => import('../pages/zhang02/huodong.vue')
  },
  {
    path: '/zhang02/form',
    name: 'zhang02-form',
    component: () => import('../pages/zhang02/form.vue')
  },
  {
    path: '/zhang02/swdt',
    name: 'zhang02-swdt',
    component: () => import('../pages/zhang02/swdt.vue')
  },
  {
    path: '/zhang02/result5',
    name: 'zhang02-result5',
    component: () => import('../pages/zhang02/result5.vue')
  },









  {
    path: '/xie03',
    name: 'xie03',
    component: () => import('../pages/xie03/index.vue')
  },
  {
    path: '/xie03/dash',
    name: 'xie03-dash',
    component: () => import('../pages/xie03/dash.vue')
  },
  {
    path: '/xie03/huodong',
    name: 'xie03-huodong',
    component: () => import('../pages/xie03/huodong.vue')
  },
  {
    path: '/xie03/playVideo2',
    name: 'xie03-playVideo2',
    component: () => import('../pages/xie03/playVideo2.vue')
  },
  {
    path: '/xie03/playVideo',
    name: 'xie03-playVideo',
    component: () => import('../pages/xie03/playVideo.vue')
  },
  {
    path: '/xie03/language',
    name: 'xie03-language',
    component: () => import('../pages/xie03/language.vue')
  },
  {
    path: '/xie03/languageMobile',
    name: 'xie03-languageMobile',
    component: () => import('../pages/xie03/languageMobile.vue')
  },
  {
    path: '/xie03/xieStudent',
    name: 'xie03-Student',
    component: () => import('../pages/xie03/student.vue')
  },
  {
    path: '/xie03/studentMobile',
    name: 'studentMobile',
    component: () => import('../pages/xie03/studentMobile.vue')
  },
  {
    path: '/xie03/stuGrade',
    name: 'xie03-stuGrade',
    component: () => import('../pages/xie03/grade.vue')
  },


  {
    path: '/kong04',
    name: 'kong04',
    component: () => import('../pages/kong04/index.vue')
  },

  {
    path: '/kong04/huodong',
    name: 'kong04-huodong',
    component: () => import('../pages/kong04/huodong.vue')
  },
  {
    path: '/kong04/form',
    name: 'kong04-form',
    component: () => import('../pages/kong04/form.vue')
  },
  // {
  //   path: '/kong04/form',
  //   name: 'kong04-form',
  //   component: () => import('../pages/kong04/form.vue')
  // },




  //从这里废弃

  {
    path: '/bigScreen/introduce',
    name: 'introduce',
    component: () => import('../pages/bigScreen/Introduce.vue')
  },
  //上面不用管，从这里开始
  {
    path: '/bigScreen/voice',
    name: 'voice',
    component: () => import('../pages/bigScreen/voice.vue')
  },
  {
    path: '/bigScreen/waiting',
    name: 'waiting',
    component: () => import('../pages/bigScreen/waiting.vue')
  },
  {
    path: '/bigScreen/result',
    name: 'result',
    component: () => import('../pages/bigScreen/result.vue')
  },
  {
    path: '/bigScreen/detail',
    name: 'detail',
    component: () => import('../pages/bigScreen/detail.vue')
  },

  {
    path: '/examinee/elderlyList',
    name: 'elderlyList',
    component: () => import('../pages/examinee/elderlyList.vue')
  },
  {
    path: '/examinee/detail',
    name: 'detail2',
    component: () => import('../pages/examinee/detail.vue')
  },



  {
    path: '/teacherGrade/grade',
    name: 'grade',
    component: () => import('../pages/teacherGrade/grade.vue')
  },
  {
    path: '/teacherGrade/studentList',
    name: 'studentList',
    component: () => import('../pages/teacherGrade/studentList.vue')
  },
  {
    path: '/teacherGrade/detail',
    name: 'detail3',
    component: () => import('../pages/teacherGrade/detail.vue')
  },
  {
    path: '/teacherGrade/result5',
    name: 'result5',
    component: () => import('../pages/teacherGrade/result5.vue')
  },

  {
    path: '/studentGrade/mobilePhone',
    name: 'mobilePhone',
    component: () => import('../pages/studentGrade/mobilePhone.vue')
  },

  {
    path: '/xie/ppt',
    name: 'ppt',
    component: () => import('../pages/xie/ppt.vue')
  },
  {
    path: '/xie/dash',
    name: 'dash',
    component: () => import('../pages/xie/dash.vue')
  },

  {
    path: '/xie/audioSelect',
    name: 'audioSelect',
    component: () => import('../pages/xie/audioSelect.vue')
  },
  {
    path: '/xie/language',
    name: 'language',
    component: () => import('../pages/xie/language.vue')
  },
  {
    path: '/xie/playVideo',
    name: 'playVideo',
    component: () => import('../pages/xie/playVideo.vue')
  },
  {
    path: '/xie/xieStudent',
    name: 'xieStudent',
    component: () => import('../pages/xie/student.vue')
  },

  // 康养小镇
  // {
  //   path: '/town/index',
  //   name: 'town-index',
  //   component: () => import('../pages/town/index.vue')
  // },
  {
    path: '/town/fall-down',
    name: 'fall-down',
    component: () => import('../pages/town/fall-down/fall-down.vue')
  },
  {
    path: '/town/fall-down-grade',
    name: 'fall-down-grade',
    component: () => import('../pages/town/fall-down/grade.vue')
  },
  {
    path: '/town/acc',
    name: 'acc',
    component: () => import('../pages/town/acc/index.vue')
  },
  {
    path: '/town/hospice-care',
    name: 'hospice-care',
    component: () => import('../pages/town/hospice-care/index.vue')
  },
  {
    path: '/town/hearing',
    name: 'hearing',
    component: () => import('../pages/town/hearing/index.vue')
  },
  {
    path: '/town/hearing-grade',
    name: 'hearing-grade',
    component: () => import('../pages/town/hearing/grade.vue')
  },
  {
    path: '/town/cognitive-care',
    name: 'cognitive-care',
    component: () => import('../pages/town/cognitive-care/index.vue')
  },
  {
    path: '/town/special-care',
    name: 'special-care',
    component: () => import('../pages/town/special-care/index.vue')
  },
  {
    path: '/town/special-care-grade',
    name: 'special-care-grade',
    component: () => import('../pages/town/special-care/grade.vue')
  },
  {
    path: '/town/mood-swing',
    name: 'mood-swing',
    component: () => import('../pages/town/mood-swing/index.vue')
  },
  {
    path: '/town/mood-swing-grade',
    name: 'mood-swing-grade',
    component: () => import('../pages/town/mood-swing/grade.vue')
  },
  {
    path: '/town/analysis',
    name: 'analysis',
    component: () => import('../pages/town/special-care/analysis.vue')
  },
  {
    path: '/town/login',
    name: 'login',
    component: () => import('../pages/login/index.vue')
  },
  {
    path: '/town/zhzhg',
    name: 'zhzhg',
    component: () => import('../pages/zhzhg/index.vue')
  },
  {
    path: '/town/zhzhg/gtjq',
    name: 'gtjq',
    component: () => import('../pages/zhzhg/gtjq.vue')
  },
  {
    path: '/town/zhzhg/ryzh',
    name: 'ryzh',
    component: () => import('../pages/zhzhg/ryzh.vue')
  },
  {
    path: '/town/zhzhg/rczh',
    name: 'rczh',
    component: () => import('../pages/zhzhg/rczh.vue')
  },
  {
    path: '/town/zhzhg/tszh',
    name: 'tszh',
    component: () => import('../pages/zhzhg/tszh.vue')
  },
  {
    path: '/town/model',
    name: 'model',
    component: () => import('../pages/zhzhg/model.vue')
  },
  {
    path: '/town/zhzhg/tszh/:projectId',
    name: 'tszh-detail',
    component: () => import('../pages/zhzhg/tszh-detail.vue')
  },
  {
    path: '/town/special-care/studentDetail',
    name: 'special-care-detail',
    component: () => import('../pages/town/special-care/studentDetail.vue')
  },

  {
    path: '/town/zhzhg/tszh2/kongResource/:id',
    name: 'kongResource',
    component: () => import('../pages/zhzhg/kongResource.vue')
  },


]

const router = new VueRouter({
  routes
})

export default router
