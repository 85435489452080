/*
 * @Autor: ROT
 * @LastEditors: ROT
 * @Description: 
 * @Date: 2021-10-08 09:40:03
 * @LastEditTime: 2023-06-07 09:39:22
 * @FilePath: \vue-fall-emergency\src\util\flexiable.js
 */


(function flexible(window, document) {
  var docEl = document.documentElement;
  var dpr = window.devicePixelRatio || 1;

  // adjust body font size
  function setBodyFontSize() {
    if (document.body) {
      document.body.style.fontSize = 12 * dpr + "px";
    } else {
      document.addEventListener("DOMContentLoaded", setBodyFontSize);
    }
  }
  setBodyFontSize();

  // set 1rem = viewWidth / 10
  function setRemUnit() {
    var rem = docEl.clientWidth / 24; // 这里默认是10等份，手动改为24，此时1rem=1920/24px即80px。（设计稿是1920px的）

    if (rem > 120 && rem<150) rem = 110
    if (rem > 110 && rem<120) rem = 80

    var height1 = docEl.clientHeight
    if(height1>1000&&height1<=1100){
      rem = 80
    }
    docEl.style.fontSize = rem + "px";
  }

  setRemUnit();

  // reset rem unit on page resize
  window.addEventListener("resize", setRemUnit);
  window.addEventListener("pageshow", function (e) {
    if (e.persisted) {
      setRemUnit();
    }
  });

  // detect 0.5px supports
  if (dpr >= 2) {
    var fakeBody = document.createElement("body");
    var testElement = document.createElement("div");
    testElement.style.border = ".5px solid transparent";
    fakeBody.appendChild(testElement);
    docEl.appendChild(fakeBody);
    if (testElement.offsetHeight === 1) {
      docEl.classList.add("hairlines");
    }
    docEl.removeChild(fakeBody);
  }
})(window, document);