<!--
 * @Autor: ROT
 * @LastEditors: ROT
 * @Description: 
 * @Date: 2023-05-23 09:17:46
 * @LastEditTime: 2023-07-19 11:39:58
 * @FilePath: \vue-fall-emergency\src\App.vue
-->
<template>
  <div id="app">
    <div
      class="back1"
      @click="back"
    ></div>

    <div
      class="platform-title"
      @click="backIndex"
      v-show="false"
    ></div>

    <router-view />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      showppt: false,
      showtitle: false,
    };
  },
  watch: {
    $route(newval) {
      console.log(newval);
      let { name } = newval;
      let whiteList = [
        "cover",
        "hu01",
        "zhang02",
        "xie03",
        "kong04",
        "hu01-mobilePhone",
        "kong04-form",
        "xie03-languageMobile",
        "zhang02-swdt",
        "zhang02-jiexiaoMB",
      ];
      if (!whiteList.includes(name)) {
        this.showtitle = true;
      } else {
        this.showtitle = false;
      }
    },
  },
  methods: {
    back() {
      this.$router.back();
    },
    togglePPT() {
      this.showppt = !this.showppt;
    },
    backIndex() {
      this.$router.push({ name: "login" });
    },
  },
  mounted() {
    let { name } = this.$route;
    let whiteList = [
      "cover",
      "hu01",
      "zhang02",
      "xie03",
      "kong04",
      "hu01-mobilePhone",
      "kong04-form",
      "xie03-languageMobile",
      "zhang02-swdt",
      "zhang02-jiexiaoMB",
    ];
    if (!whiteList.includes(name)) {
      this.showtitle = true;
    } else {
      this.showtitle = false;
    }
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html,
body,
#app {
  width: 100%;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}

.back1 {
  cursor: pointer;
  position: fixed;
  left: 3vmax;
  top: 3vmin;
  z-index: 999;
  background-size: contain;
  background-repeat: no-repeat;
  width: 5vmax;
  height: 4vmin;
  background-image: url("/public/img/btn_exit.png");
}
.back1:hover {
  background-image: url("/public/img/btn_exit_press.png");
}

.platform-title {
  position: fixed;
  left: 7vmax;
  top: 3vmin;
  width: 40vmax;
  height: 4vmin;
  background-image: url("/public/img/title.png");
  background-size: contain;
  background-repeat: no-repeat;
  cursor: pointer;
  cursor: pointer;
  z-index: 999;
}
</style>
